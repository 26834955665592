import logo from './assets/images/logo.svg';
import './assets/scss/main.scss';

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Home from './screens/Home/Home';

export default function App() {
  return (
    <Router>
        <Switch>
          <Route path="/">
            <Home />
          </Route>
          <Route path="/thank-you">
            <>test</>
          </Route>
        </Switch>

    </Router>
  );
}
