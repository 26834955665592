import './Card.scss'
import React, {useState, useEffect} from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useLocation } from 'react-router-dom'
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from 'react-toastify';
import axios from 'axios';
  
const Card = (props) => {

    const elements = useElements();
    const stripe = useStripe();
    const history = useLocation();

    // const [amount, setAmount] = useState(25);
    // const [invoice, setInvoice] = useState('1234');
    // const [client, setClient] = useState('Slava Client');
    // const [adviser, setAdviser] = useState('Slava Adviser');
    // const [email, setMail] = useState('123@mail.com')
    // const [loading, setLoading] = useState(false);
    // const [cardValid, setCardValid] = useState(true);
    // const [stripeError, setStripeError] = useState('');
    const [amount, setAmount] = useState();
    const [invoice, setInvoice] = useState('');
    const [client, setClient] = useState('');
    const [adviser, setAdviser] = useState('');
    const [email, setMail] = useState('')
    const [loading, setLoading] = useState(false);
    const [cardValid, setCardValid] = useState(true);
    const [stripeError, setStripeError] = useState('');
    const override = css`
        position: absolute;
        left: 45%;
        top:50%;
    `;

    const cardStyle = {
        iconStyle: "solid",
        style: {
          base: {
            iconColor: "#fff",
            color: "#fff",
            fontWeight: 400,
            fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
    
            "::placeholder": {
              color: "#BFAEF6"
            },
            ":-webkit-autofill": {
              color: "#fce883"
            }
          },
          invalid: {
            iconColor: "#FFC7EE",
            color: "#FFC7EE"
          }
        }
    };

    useEffect(()=> {
        if(stripe && elements){
            
            let cardElement = elements.getElement(CardElement)
            cardElement.on('change', function(event) {
                if (event.error) {
                    console.log(event.error)
                    setCardValid(false)
                    setStripeError(event.error.message)
                } else {
                    console.log('VALID')
                    setCardValid(true)
                }
              });
        }

    }, [stripe, elements])


    const validate = () => {
        const cardSelector = document.querySelector('.card-element').classList.contains('StripeElement--empty');
    
        if(cardSelector){
            setCardValid(false);
        }
    
        if(invoice && invoice.length < 3){
            toast.warning("Invoice number should be more then 2 characters");
            setLoading(false)
            return false;
        }

        if(email && !validateEmail(email)){
            toast.warning("Please use valid email to receive transaction receipt");
            setLoading(false)
            return false;
        }

        if(!cardValid){
            toast.warning(stripeError ? stripeError : "Please use valid card details");
            setLoading(false)
            return false;
        }


        return true;
    }

    const validateEmail = (emailStr) => {
        return String(emailStr)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };


    const handle = async (e) => {
        e.preventDefault();
        setLoading(true);

        if(!validate()) return;

        if(!stripe || !elements){

            console.log('@@@@')
            return ;
        }

        const paymentMethod = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
          });
      
          console.log(paymentMethod.paymentMethod.id)

        const {clientSecret} = await fetch(process.env.REACT_APP_SERVER_URL+'?' + new URLSearchParams({
          paymentMethodType: 'card',
          currency: 'gbp',
          client: client,
          invoice: invoice,
          adviser: adviser,
          amount: amount,
          receipt_email: email,
          paymentMethodId: paymentMethod.paymentMethod.id
        }), {
            method: "get",
            headers : {
              
            }

        }).then(r => r.json())
        
        await stripe.confirmCardPayment(
            clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement)
                }
            }
        )
        setLoading(false);
        
        props.screen(2)
    }

    return (
        
        <div className='payment-form-wrap'>
            <div className="cell example example5" id="example-5">
                <form>
                    <fieldset>
                        <legend className="card-only" data-tid="elements_examples.form.pay_with_card">Invoice details</legend>
                        <div className="row">
                            <div className="field">
                            <label htmlFor="example5-adviserName" data-tid="elements_examples.form.adviserName_label">Adviser's Name</label>
                            <input id="example5-adviserName"
                            className="input" type="text" placeholder="Jane Doe" value={adviser} onChange={(e) => setAdviser(e.currentTarget.value)} autoComplete="adviserName" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="field">
                            <label htmlFor="example5-invoiceNumber">Invoice number</label>
                            <input id="example5-invoiceNumber" className="input" value={invoice} type="text" required={true} onChange={(e) => setInvoice(e.currentTarget.value)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="field">
                            <label htmlFor="example5-totalInvoiceAmount" >Total invoice amount (&#163;)</label>
                            <input id="example5-totalInvoiceAmount"  value={amount} onChange={(e)=>setAmount(e.currentTarget.value)} className="input" type="text" required={true} />
                            </div>
                        </div>
                        </fieldset>
                        <br />
                        <br />
                        <fieldset>
                        <legend className="card-only">Payment details</legend>
                        <div className="row">
                            <div className="field">
                            <label htmlFor="example5-name" >Your Name</label>
                            <input id="example5-name" onChange={(e) => setClient(e.currentTarget.value)} 
                            className="input" type="text" value={client} placeholder="Jane Doe" required={true} autoComplete="name" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="field">
                            <label htmlFor="example5-name">Your Email</label>
                            <input id="example5-name" onChange={(e) => setMail(e.currentTarget.value)}
                            className="input" type="text" value={email} placeholder="jane@doe.com" required={true} autoComplete="name" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="field">
                            <label htmlFor="example5-card" data-tid="elements_examples.form.card_label">Card</label>
                            <CardElement options={cardStyle} className="card-element" />
                            </div>
                        </div>
                        { amount && invoice &&
                          <button type="submit" onClick={handle} data-tid="elements_examples.form.pay_button" id="paymentSubmitButton" >
                            Pay &#163;
                            <span id="totalPaymentAmount">{amount}</span>
                          </button>
                        }
                      
                    </fieldset>
                </form>       
            </div>
            <ClipLoader loading={loading} size={150} css={override} />

        </div>
    )
}

export default Card;