import './Home.scss';
import React, {useState} from 'react';
import { withRouter } from 'react-router-dom'

import Card from '../../components/Card'
import ThankYou from '../../components/ThankYou';

import logo from '../../assets/images/logo.png'
import logo2 from '../../assets/images/VPlogo.png'

import phone from '../../assets/images/phone.svg'
import mail from '../../assets/images/mail.svg'

import pin from '../../assets/images/pin.svg'
import linkedin from '../../assets/images/linkedin.svg'
import twitter from '../../assets/images/twitter.svg'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Home = () => {

    const [screen, setScreen] = useState(1);

    return (
        <div className='wrapper'>
            <div className="stripes">
                <div className="stripe s1"></div>
                <div className="stripe s2"></div>
                <div className="stripe s3"></div>
            </div>
            <div className='main'>
                <div className="logo">
                    <img src={logo2} alt="" />
                    <h1>ValidPath Payment Portal</h1>
                </div>
                {screen === 1 &&
                    <Card screen={setScreen}/>
                }

                {screen === 2 &&
                    <ThankYou screen={setScreen}/>
                }

            </div>
            <footer className="vp-footer pt-5 bg-white">
                <div className="container text-center">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 mb-4 text-left column-1" style={{fontSize: '0.9em'}}> 
                            <img className="pb-4" src={logo} />
                            <p className="pb-2">A network of Independent Financial Advisers.</p>
                            <p>ValidPath Limited is authorised and regulated by the Financial Conduct Authority under FRN 197107.</p>
                            <p>ValidPath © Copyright 2020
                            <br /> All Rights Reserved</p>
                        </div>
                        <div className="col-lg-1 line-vl">
                            <div className="vl"></div>
                        </div>
                        <div className="col-lg-5 col-md-4 col-sm-6 mb-4 text-left column-2">
                            <h5 className="font-weight-bold pb-4 vp-column">Contact</h5>
                            <p><img className="mr-3" src={phone} width="20" />029 2049 4495</p>
                            <p><img className="mr-3" src={mail} width="20" /><a style={{color: "#121212"}} href="mailto:clients@validpath.co.uk">clients@validpath.co.uk</a></p>
                            <div className="d-flex mobile-centered-flex align-items-start"> 
                                <img className="mr-3 pt-1" src={pin} width="20" />
                                <p className="mb-0">Unit 16, The Globe Centre,
                                    <br /> Wellfield Road, Cardiff <br /> CF24 3PE</p>
                            </div>
                            <div className="d-flex mobile-centered-flex pt-3 pl-4">
                                <a target="_blank" href="https://www.linkedin.com/company/validpath">
                                    <img className="ml-3 pt-1" src={linkedin} width="20" />
                                </a>
                                <a target="_blank" href="https://twitter.com/ValidPath">
                                    <img className="ml-4 pt-1" src={twitter} width="20" />
                                </a>
                            </div>
                            {/* <div className="d-flex mobile-centered-flex align-items-start"> <img className="mr-3 pt-1" src="img/icons/pin.svg" width="20" />
                        <p className="mb-0"> Shepherd Market,
                            <br />Mayfair, London, W1J 7JY </p>
                    </div> */}
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 mb-4 text-left column-3"  style={{fontSize: '0.9em'}}>
                            <h5 className="font-weight-bold pb-4">Pages</h5>
                            <ul className="unlisted-footer pl-0">
                                <li><a target="_blank" href="https://validpath.co.uk/memberclients">For Member Clients</a></li>
                                <li><a target="_blank" href="https://join.validpath.co.uk/about-us.php">About Us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            <ToastContainer />
        </div>
    )
}

export default withRouter(Home)